import axios from 'axios';
import { processImage } from '../../lib/veryfi';
import store from './store/index';
import api from './api';


const instance = axios.create();
delete instance.defaults.headers.common['X-HW-Profile-Token'];

const uploader = {
    async key (whichType) {
        const params = {
            uid: store.state.profile.id,
            type: whichType,
            language: store.state.app.userLanguage,
        };

        return axios.post(`${api.base}/hello-uploader/key`, params);

    },
    async upload (params, keyData) {
        const formData = new FormData();
        formData.append('upload', params.file, params.name);

        const { uploadUrl } = keyData.data;

        try {
            const axiosInstance = axios.create({});
            // token not needed for uploader call and causes CORS issues, so remove it
            delete axiosInstance.defaults.headers.common['X-HW-Profile-Token'];
            delete axiosInstance.defaults.headers.common['x-aws-waf-token'];

            const options = { headers: { 'Content-Type': undefined } };

            const response = await axiosInstance.post(uploadUrl, formData, options);

            return {
                type: params.type,
                ...response.data,
            };
        }
        catch (error) {
            console.error('could not upload file', error);
        }
    },
    async veryfiProcessImage (image, deviceData) {
        let response;

        const
            clientId = store.state.app.veryfiClientId,
            username = store.state.app.veryfiUsername,
            apiKey   = store.state.app.veryfiApiKey;

        try {
            response = processImage(image, clientId, username, apiKey, deviceData);
        }
        catch (err) {
            return err;
        }

        return response;
    },
    async entries (params, veryfiData) {
        try {
            params.veryfiData = veryfiData;
            params.photo = params.outputs.orig.url;

            const response = await axios.post(`${api.base}/receipts/`, params);
            // factored code here to get immediate receipt remaining count on hub page.
            const {
                num_pending_receipts,
                upload_sweeps_count,
            } = response.data.result;

            store.commit('profile/updateProfile', {
                num_pending_receipts,
                upload_sweeps_count,
            });
            return response;
        }
        catch (err) {
            return err;
        }
    },
    async submit (params, veryfiData) {
        const keyData = await this.key('photo');
        const uploaderResponse = await this.upload(params, keyData);
        return this.entries(uploaderResponse, veryfiData);
    },
};

export default uploader;
