import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "promotion-optin-item" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "pre-headline" }
const _hoisted_4 = { class: "full-width opt-in-container" }
const _hoisted_5 = { class: "disclaimer full-width" }

import { ref } from 'vue';
    import { useComponent } from '@composables/component';
    import { BaseToggle } from '@components/form';

    
export default {
  __name: 'PromotionOptin',
  props: {
        data: {
            type: Object,
            required: true,
        },
    },
  emits: ['update'],
  setup(__props, { emit: __emit }) {

    const emit = __emit;

    

    const value = ref(false);
    const promoName = __props.data.name.split('_')[1];

    const sendUpdate = (newValue) => {
        emit('update', { name: __props.data.name });
    };

    const { t, vT } = useComponent(['promotions', 'catalog_items']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(t)(`catalog_items:promotion_${_unref(promoName)}.thumbnail.src`),
      alt: _unref(t)(`catalog_items:promotion_${_unref(promoName)}.thumbnail.alt`)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("h3", _hoisted_3, null, 512), [
        [_unref(vT), `promotions:${_unref(promoName)}.optin.pre_headline`]
      ]),
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_unref(vT), `promotions:${_unref(promoName)}.optin.headline`]
      ]),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_unref(vT), `promotions:${_unref(promoName)}.optin.body`]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_unref(BaseToggle), {
        modelValue: value.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((value).value = $event)),
        name: __props.data.name,
        label: _unref(t)(`promotions:optin_modal.${_unref(promoName)}`, { rulesUrl: __props.data.siteUrl }),
        onUpdate: sendUpdate
      }, null, 8, ["modelValue", "name", "label"])
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_5, null, 512), [
      [_unref(vT), { key: `promotions:${_unref(promoName)}.optin.disclaimer`, rulesUrl: __props.data.siteUrl }]
    ])
  ]))
}
}

}