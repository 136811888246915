
/* This is client-side code. */
/* eslint-env browser */
import validator from 'rocketship-validator';
import base from './validators.js';

const { isFilled } = validator;

const guards = {
    ...base,
    register: {
        ...base.register,
        'g-recaptcha-response-v3': { requireCaptcha },
    },
    forgotPassword: base.forgotPassword,
    login: base.login,
    viral: base.viral,
    faqContact: base.faqContact,
    passwordReset: base.passwordReset,
    updateName: base.updateName,
    updateAddress: base.updateAddress,
    updatePassword: base.updatePassword,
    readyRefreshAdd: base.readyRefreshAdd,
    passwordMetaData: base.passwordMetaData,
};

function requireCaptcha (value, field, form) {
    if (!isFilled(value)) {
        // Error out early if we don't have a response.
        this.addError(field, 'NULL');
        return false;
    }
    return true;
}

export const {
    register,
    login,
    viral,
    faqContact,
    forgotPassword,
    passwordReset,
    updateName,
    updateAddress,
    updatePassword,
    readyRefreshAdd,
    passwordMetaData,
} = guards;
export default guards;
